import "core-js/modules/es.array.push.js";
import { userInfo } from '@/api/index';
(function () {
  // 在标准 375px 适配下，100px = 1rem;
  var baseFontSize = 100;
  var baseWidth = 375;
  var set = function () {
    var clientWidth = document.documentElement.clientWidth || window.innerWidth;
    var rem = 100;
    if (clientWidth != baseWidth) {
      rem = Math.floor(clientWidth / baseWidth * baseFontSize);
    }
    document.querySelector('html').style.fontSize = rem + 'px';
  };
  set();
  window.addEventListener('resize', set);
})();
export default {
  name: 'App',
  components: {},
  data() {
    return {
      token: ''
    };
  },
  created() {
    this.token = localStorage.getItem('TOKEN');
    if (!this.token || this.token === 'undefined') {
      this.getToken();
    }
    this.getUserInfo();
  },
  methods: {
    getToken() {
      const getQueryData = () => {
        let oSearchItem = {};
        let searchString = window.location.search;
        let reg = /[?&][^?&]+=[^?&]+/g;
        let arr = searchString.match(reg);
        if (arr) {
          arr.forEach(item => {
            let tempArr = item.substring(1).split('=');
            let key = decodeURIComponent(tempArr[0]);
            let value = decodeURIComponent(tempArr[1]);
            oSearchItem[key] = value;
          });
        }
        return oSearchItem;
      };
      localStorage.setItem('TOKEN', getQueryData().access_token);
    },
    getUserInfo() {
      userInfo().then(res => {
        if (!res) {
          console.log('null');
        } else {
          this.$router.push({
            path: '/login'
          });
        }
      });
    }
  }
};