import request from '@/utlis/request'

// 章节数据
export function getPlayData (id) {
  return request({
    url: '/api/chapters/' + id
  })
}

// 获取用户信息
export function userInfo () {
  return request({
    url: '/api/viewer',
  })
}

// 更新用户信息
export function updateInfo (data) {
  return request({
    url: '/api/viewer',
    method: 'post',
    data
  })
}