import axios from "axios"
const instance = axios.create({
  baseURL: process.env.VUE_APP_ENV == 'production' ? process.env.VUE_APP_BASE_API : '',
  timeout: 3000,
})


instance.interceptors.request.use(config => {
  const token = localStorage.getItem('TOKEN')
  if (token && token !== 'undefined') {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config
}, error => {
  return Promise.reject(error)
})

instance.interceptors.response.use(response => {
  return response.data
}, error => {
  return Promise.reject(error)
})

export default instance