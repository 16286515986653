import { createRouter, createWebHistory } from 'vue-router'
const routes = [
    {
        path: '/',
        component: () => import('../pages/index.vue'),
    },
    {
        path: '/login',
        component: () => import('../pages/index.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/activation',
        component: () => import('../components/tips/activation.vue')
    },
    {
        path: '/limit',
        component: () => import('../components/tips/limit.vue')
    },
    {
        path: '/error',
        component: () => import('../components/tips/activation.vue')
    },
    {
        path: '/detailPage',
        component: () => import('../pages/detailPage/index.vue'),
        meta: {
            requiresAuth: true
        },
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeResolve((to, form, next) => {
    let token = localStorage.getItem('TOKEN')
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!token || token === 'undefined') {
            next({
                path: '/'
            })
        } else {
            next()
        }
    }
})

export default router